import React, {
    Suspense,
    Fragment,
    lazy
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';

type Routes = {
    exact?: boolean;
    path?: string | string[];
    guard?: any;
    layout?: any;
    component?: any;
    routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes: Routes = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView'))
    },
    {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView/OrganizationPatientIdLogin'))
    },
    {
        exact: true,
        path: '/login/:organization_id',
        component: lazy(() => import('src/views/auth/LoginView/OrganizationPatientIdLogin'))
    },
    {
        exact: true,
        path: '/login/patient/:patient_id',
        component: lazy(() => import('src/views/auth/LoginView/PatientIdLogin'))
    },
    {
        exact: true,
        path: '/login/patient/:patient_id/:url?',
        component: lazy(() => import('src/views/auth/LoginView/PatientIdLogin'))
    },
    {
        exact: true,
        path: '/register',
        component: lazy(() => import('src/views/auth/RegisterView/OrganizationPatientIdRegister'))
    },
    {
        exact: true,
        path: '/register/:organization_id',
        component: lazy(() => import('src/views/auth/RegisterView/OrganizationPatientIdRegister'))
    },
    {
        exact: true,
        path: '/register/simple/:patient_id',
        component: lazy(() => import('src/views/auth/RegisterView/RegisterSimple'))
    },
    {
        exact: true,
        path: '/register/simple/:patient_id/:url?',
        component: lazy(() => import('src/views/auth/RegisterView/RegisterSimple'))
    },
    {
        path: '/prescription/:prescription_public_id',
        layout: DashboardLayout,
        component: lazy(() => import('src/views/prescriptions/PrescriptionDetailsView'))
    },
    {
        exact: true,
        path: '/passwordRecovery/:organization_id',
        component: lazy(() => import('src/views/auth/PasswordRecoveryView/OrganizationPatientIdRecovery'))
    },
    {
        exact: true,
        path: '/passwordRecovery/simple/:patient_id',
        component: lazy(() => import('src/views/auth/PasswordRecoveryView/IdRecovery'))
    },
    {
        exact: true,
        path: '/reset/password/:token/:redirect_url?',
        component: lazy(() => import('src/views/auth/ResetPasswordView'))
    },
    {
        exact: true,
        path: '/eConsent/finish/:eConsent_id',
        component: lazy(() => import('src/views/eConsent/FinishEConsentView'))
    },
    {
        exact: true,
        path: '/app/eConsent/:eConsent_id/dataValidation/',
        component: lazy(() => import('src/views/eConsent/DataVerification'))
    },
    {
        exact: true,
        path: '/app/eConsent/:eConsent_id/:organization_id/login',
        component: lazy(() => import('src/views/eConsent/LoginView'))
    },
    {
        exact: true,
        path: '/app/eConsent/:eConsent_id/:organization_id/register',
        component: lazy(() => import('src/views/eConsent/RegisterView'))
    },
    {
        exact: true,
        path: '/healthNavigator/:id',
        component: lazy(() => import('src/views/healthNavigator/index'))
    },
    {
        exact: true,
        path: '/healthNavigator/finished',
        component: lazy(() => import('src/views/healthNavigator/Finish/index'))
    },
    {
        exact: true,
        path: '/auth/:patient_id/:url?',
        component: lazy(() => import('src/views/auth/index'))
    },
    {
        exact: true,
        path: '/anonymous/questionnaire/:token',
        component: lazy(() => import('src/views/questionnaires/AnonymousIframeView/index'))
    },
    {
        exact: true,
        path: '/questionnaires/:questionnaire_public_id',
        component: lazy(() => import('src/views/questionnaires/QuestionnaireView/AnswerQuestionnaireAnonymously/index'))
    },
    {
        exact: true,
        path: '/questionnaires/pdf/:questionnaire_public_id',
        component: lazy(() => import('src/views/questionnaires/pdf'))
    },
    {
        path: '/iframe/:token/:optional_params?',
        component: lazy(() => import('src/views/iframe/index'))
    },
    {
        exact: true,
        path: '/3D/:model_3d_id',
        component: lazy(() => import('src/views/models/ModelsDetailsView'))
    },
    {
        exact: true,
        path: '/trajectory/:id',
        component: lazy(() => import('src/views/trajectories/trajectoryDetailsViewWithoutAuthentication'))
    },
    {
        exact: true,
        path: '/trajectory/historial/:id',
        component: lazy(() => import('src/views/trajectories/trajectoryHistorialViewWithoutAuthentication'))
    },
    {
        exact: true,
        path: '/trajectory/historial/:id/prescriptions',
        component: lazy(() => import('src/views/trajectories/trajectoryHistorialView/PrescriptionView'))
    },
    {
        exact: true,
        path: '/trajectory/historial/:id/eConsents',
        component: lazy(() => import('src/views/trajectories/trajectoryHistorialView/EConsentView'))
    },
    {
        exact: true,
        path: '/trajectory/historial/:id/videos',
        component: lazy(() => import('src/views/trajectories/trajectoryHistorialView/VideoView'))
    },
    {
        exact: true,
        path: '/trajectory/historial/:id/files',
        component: lazy(() => import('src/views/trajectories/trajectoryHistorialView/FileView'))
    },
    {
        exact: true,
        path: '/trajectory/historial/:id/links',
        component: lazy(() => import('src/views/trajectories/trajectoryHistorialView/LinkView'))
    },
    {
        exact: true,
        path: '/eConsent',
        component: lazy(() => import('src/views/eConsent/eConsentListView'))
    },
    {
        exact: true,
        path: '/eConsent/:id',
        component: lazy(() => import('src/views/eConsent/eConsentDetailView'))
    },
    {
        exact: true,
        path: '/eConsent/:eConsent_id/dataValidation/',
        component: lazy(() => import('src/views/eConsent/DataVerification'))
    },
    {
        exact: true,
        path: '/eConsent/signature/:eConsent_public_id/:email_id/:DocGUI/:lang',
        component: lazy(() => import('src/views/eConsent/SignatureView'))
    },
    {
        exact: true,
        path: '/eConsent/:id/:DocGUI',
        component: lazy(() => import('src/views/eConsent/eConsentDetailView'))
    },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app',
                component: lazy(() => import('src/views/reports/DashboardView'))
            },
            {
                exact: true,
                path: '/app/register',
                component: lazy(() => import('src/views/auth/RegisterView/OrganizationPatientIdRegister'))
            },
            {
                exact: true,
                path: '/app/prescriptions',
                component: lazy(() => import('src/views/prescriptions/PrescriptionListView'))
            },
            {
                exact: false,
                path: '/app/account',
                component: lazy(() => import('src/views/account/AccountView'))
            },
            {
                exact: true,
                path: '/app/profile',
                component: lazy(() => import('src/views/account/AccountView/General'))
            },
            {
                exact: true,
                path: '/app/prescription/:prescription_public_id',
                component: lazy(() => import('src/views/prescriptions/PrescriptionDetailsView'))
            },
            {
                exact: true,
                path: '/app/eConsent',
                component: lazy(() => import('src/views/eConsent/eConsentListView'))
            },
            {
                exact: true,
                path: '/app/eConsent/:id',
                component: lazy(() => import('src/views/eConsent/eConsentDetailView'))
            },
            {
                exact: true,
                path: '/app/trajectories',
                component: lazy(() => import('src/views/trajectories/trajectoriesListView'))
            },
            {
                exact: true,
                path: '/app/trajectory/:id',
                component: lazy(() => import('src/views/trajectories/trajectoryDetailsView'))
            },
            {
                exact: true,
                path: '/app/trajectory/historial/:id',
                component: lazy(() => import('src/views/trajectories/trajectoryHistorialView'))
            },
            {
                exact: true,
                path: '/app/trajectory/historial/:id/prescriptions',
                component: lazy(() => import('src/views/trajectories/trajectoryHistorialView/PrescriptionView'))
            },
            {
                exact: true,
                path: '/app/trajectory/historial/:id/eConsents',
                component: lazy(() => import('src/views/trajectories/trajectoryHistorialView/EConsentView'))
            },
            {
                exact: true,
                path: '/app/trajectory/historial/:id/videos',
                component: lazy(() => import('src/views/trajectories/trajectoryHistorialView/VideoView'))
            },
            {
                exact: true,
                path: '/app/trajectory/historial/:id/files',
                component: lazy(() => import('src/views/trajectories/trajectoryHistorialView/FileView'))
            },
            {
                exact: true,
                path: '/app/trajectory/historial/:id/links',
                component: lazy(() => import('src/views/trajectories/trajectoryHistorialView/LinkView'))
            },
            {
                exact: true,
                path: '/app/eConsent/:id/:DocGUI',
                component: lazy(() => import('src/views/eConsent/eConsentDetailView'))
            },
            {
                exact: true,
                path: '/app/eConsent/signature/:eConsent_public_id/:email_id/:DocGUI/:lang',
                component: lazy(() => import('src/views/eConsent/SignatureView'))
            },
            {
                exact: true,
                path: '/app/questionnaires',
                component: lazy(() => import('src/views/questionnaires/QuestionnaireListView'))
            },
            {
                exact: true,
                path: '/app/questionnaires/:questionnaire_id',
                component: lazy(() => import('src/views/questionnaires/QuestionnaireView/AnswerQuestionnaire/index'))
            },
            {
                exact: true,
                path: '/app/healthNavigator',
                component: lazy(() => import('src/views/healthNavigator/NavigatorListView/index'))
            },
            {
                exact: true,
                path: '/app/healthNavigator/:id',
                component: lazy(() => import('src/views/healthNavigator/index'))
            },
            {
                exact: true,
                path: '/app/channels',
                component: lazy(() => import('src/views/channel/ChannelListView'))
            },
            {
                exact: true,
                path: '/app/channel/:channelView',
                component: lazy(() => import('src/views/channel/ChannelDetailView'))
            },
            {
                exact: true,
                path: '/app/dashboard',
                component: lazy(() => import('src/views/dashboard/index'))
            },
        ]
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/'
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    }
];

export default routes;
