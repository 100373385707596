import * as sanitizeHtml from 'sanitize-html';
import parse from "html-react-parser";
import axios from 'axios';

export const getWSConfig = () => {
    let token = localStorage.getItem('accessToken');
    let config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return config;
}

/**
 * 
 * @param htmlText a string with html tags and text
 * @returns a parsed html compiled with only safe tags
 */
export const cleanHtml = (htmlText) => {
    const cleanHtml = sanitizeHtml(htmlText, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'li', 'ol', 'ul', 'u', 'h1', 'h2', 'h3', 'h4', 'h5'],
        allowedAttributes: {
            a: ['href', 'target']
        }
    });
    return parse(cleanHtml);
}

export const getAndStoreKalturaVideoPlayerId = async (organization_id?: number) => {

    let params = { "organization_id": 0 }
    if (!organization_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        if (user && user.organization_id) {
            params = { "organization_id": user.organization_id }
        }
    }
    else {
        params = { "organization_id": organization_id }
    }

    try {
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/organization/1.0/get/kaltura/player/id', params);
        if (response && response.data) {
            localStorage.setItem('kaltura_video_player_id_patient_portal', JSON.stringify(response.data));
            return JSON.stringify(response.data);
        }
    } catch (error) {
        console.log(error);
        return '';
    }
}

export const deleteKalturaVideoPlayerIdFromLocalStorage = () => {
    localStorage.removeItem('kaltura_video_player_id_patient_portal');
}