import React, {
  useRef,
  useState
} from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Avatar from 'react-avatar';
import { Box, ButtonBase, Hidden, Menu, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useAuth from 'src/hooks/useAuth';
import type { Patient } from 'src/types/patient';
import { FormattedMessage } from 'react-intl';
import { theme } from 'src/theme';
import {
  User as UserIcon,
  Settings as SettingsIcon,
  LogOut as LogOutIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>(null);
  const { logout } = useAuth();
  const { isAuthenticated } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);
  const patient: Patient = JSON.parse(localStorage.getItem('user'));

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      logout();
      history.push('/login/' + patient.organization_id);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };
  const handleOpenConfig = (): void => {
    handleClose();
    return history.push('/app/account')
  };
  const handleOpenProfile = (): void => {
    handleClose();
    return history.push('/app/profile')
  };

  return <>
    <Box
      display="flex"
      alignItems="center"
      component={ButtonBase}
      onClick={handleOpen}
      // @ts-ignore
      ref={ref}
    >
      <Avatar
        name={patient.name + " " + patient.last_name}
        maxInitials={2}
        size="30"
        round={true}
        color="#E0E0E0"
        className={classes.avatar}
      />
      <Hidden mdDown>
        <Typography
          variant="h6"
          color="textPrimary"
        >
          {patient.name} {" "} {patient.last_name}
        </Typography>
      </Hidden>
    </Box>
    <Menu
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      keepMounted
      PaperProps={{ className: classes.popover }}
      anchorEl={ref.current}
      open={isOpen}
    >
      {isAuthenticated && (
        <Box>
          <MenuItem onClick={() => handleOpenProfile()}>
            <UserIcon style={{ marginRight: 8, color: theme.palette.neutral[500] }} />
            <FormattedMessage id="layouts.DashboardLayout.TopBar.Account.profile" />
          </MenuItem>
          <MenuItem onClick={() => handleOpenConfig()}>
            <SettingsIcon style={{ marginRight: 8, color: theme.palette.neutral[500] }} />
            <FormattedMessage id="layouts.DashboardLayout.TopBar.Account.configuration" />
          </MenuItem>
          <Box style={{ height: '16px' }}> { }</Box>
          <MenuItem onClick={handleLogout}>
            <LogOutIcon style={{ marginRight: 8, color: theme.palette.neutral[500] }} />
            <FormattedMessage id="layouts.DashboardLayout.TopBar.Account.logout" />
          </MenuItem>
        </Box>
      )}
      {!isAuthenticated && (
        <MenuItem
          component={RouterLink}
          to="/"
        >
          <FormattedMessage id="layouts.DashboardLayout.TopBar.Account.account" />
        </MenuItem>
      )}
    </Menu>
  </>;
}

export default Account;
